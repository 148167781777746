// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/commons/Navbar';
import Footer from './components/commons/Footer';
import { initGA, logPageView } from './analytics';

// Componentes de las páginas
import Home from './components/pages/Home';
import Hosting from './components/pages/Hosting';
import OurServices from './components/pages/OurServices';
import TecnicalSupport from './components/pages/SupportPlans';
import MantenimientoWeb from './components/pages/MaintenancePlans';
import SeoRedes from './components/pages/SEOPlans';
import DesarrolloWeb from './components/pages/WebDevelopmentPlans';
import PoliticasCancelacion from './components/pages/CancellationConditions';
import HowItWorks from './components/pages/HowItWorks';
import MailSetupGuide from './components/FAQs/MailSetupGuide';
import DeveloperPlugins from './components/pages/DeveloperPlugins';

// Componentes de Hosting Share
import HostingShare from './components/hosting-share/HostingShare';
import HostingShareFeatures from './components/hosting-share/HostingShareFeatures';
import HostingShareFaqs from './components/hosting-share/HostingShareFaqs';
import HostingSharePrecios from './components/hosting-share/HostingSharePrecios';

// Componentes de Hosting Cloud
import HostingCloud from './components/hosting-cloud/HostingCloud';
import HostingCloudCaracteristicas from './components/hosting-cloud/HostingCloudCaracteristicas';
import HostingCloudPrecios from './components/hosting-cloud/HostingCloudPrecios';

// Definición de rutas
const routes = [
  { path: '/', element: <Home /> },
  { path: '/hosting', element: <Hosting /> },
  { path: '/services', element: <OurServices /> },
  { path: '/hosting-share', element: <HostingShare /> },
  { path: '/soporte-tecnico', element: <TecnicalSupport /> },
  { path: '/desarrollo-web', element: <DesarrolloWeb /> },
  { path: '/seo-redes', element: <SeoRedes /> },
  { path: '/mantenimiento-web', element: <MantenimientoWeb /> },
  { path: '/hosting-share/features', element: <HostingShareFeatures /> },
  { path: '/hosting-share/faqs', element: <HostingShareFaqs /> },
  { path: '/hosting-share/precios', element: <HostingSharePrecios /> },
  { path: '/hosting-cloud', element: <HostingCloud /> },
  { path: '/hosting-cloud/caracteristicas', element: <HostingCloudCaracteristicas /> },
  { path: '/hosting-cloud/precios', element: <HostingCloudPrecios /> },
  { path: '/cancelaciones', element: <PoliticasCancelacion /> },
  { path: '/how-to-work', element: <HowItWorks /> },
  { path: '/faqs', element: <MailSetupGuide /> },
  { path: '/desarrollo-plugins', element: <DeveloperPlugins /> },
];

const RouteChangeTracker = () => {
  const location = useLocation();
  useEffect(() => {
    logPageView(); // Track page view on route change
  }, [location]);

  return null;
};

const App = () => {
  useEffect(() => {
    initGA('G-SSYRXW1WFR'); // Initialize Google Analytics
  }, []);

  return (
    <Router>
      <RouteChangeTracker />
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            {routes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
