import React, { useState } from 'react';
import { FaPlug, FaTools, FaLaptopCode, FaShoppingCart, FaWhatsapp, FaCodeBranch, FaWordpress } from 'react-icons/fa';
import { useSpring, animated } from '@react-spring/web';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

const DeveloperPlugins = () => {
  const [showInfo, setShowInfo] = useState(false);
  const infoSpring = useSpring({
    opacity: showInfo ? 1 : 0,
    transform: showInfo ? 'translateY(0)' : 'translateY(-20px)',
  });

  const handleInfoToggle = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-indigo-600 py-12 text-center text-white">
        <h1 className="text-4xl font-extrabold">Potencia tu WordPress con Plugins Personalizados</h1>
        <p className="mt-4 text-lg">
          KUV Digital desarrolla plugins a medida para WordPress, WooCommerce, Bootstrap y más. Optimiza y expande la funcionalidad de tu plataforma de forma profesional.
        </p>
      </div>

      {/* Descripción de Ventas */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Plugins Hechos a la Medida de tu Negocio</h2>
        <p className="text-gray-700 text-lg leading-relaxed">
          En <strong>KUV Digital</strong>, creamos plugins personalizados que se adaptan perfectamente a las necesidades de tu sitio WordPress o tienda WooCommerce. Ya sea que necesites nuevas funcionalidades, integraciones avanzadas o mejoras en tu diseño con frameworks como <strong>Bootstrap</strong>, nuestro equipo puede hacerlo realidad.
        </p>
      </div>

      {/* Tipos de Plugins */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h3 className="text-2xl font-bold text-gray-800 mb-6 text-center">¿Qué tipo de plugin necesitas?</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {/* Plugins WooCommerce */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <FaShoppingCart className="h-12 w-12 text-indigo-600 mx-auto mb-4" />
            <h4 className="text-xl font-bold mb-2">Plugins para WooCommerce</h4>
            <p className="text-gray-600">
              Personaliza y mejora tu tienda online con plugins exclusivos para WooCommerce.
            </p>
          </div>
          {/* Plugins WhatsApp */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <FaWhatsapp className="h-12 w-12 text-indigo-600 mx-auto mb-4" />
            <h4 className="text-xl font-bold mb-2">Plugins para WhatsApp</h4>
            <p className="text-gray-600">
              Integra WhatsApp en tu sitio para ofrecer soporte directo y aumentar la conversión.
            </p>
          </div>
          {/* Plugins para Softland */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <FaCodeBranch className="h-12 w-12 text-indigo-600 mx-auto mb-4" />
            <h4 className="text-xl font-bold mb-2">Plugins para Softland</h4>
            <p className="text-gray-600">
              Conecta tu sistema Softland con WordPress o WooCommerce de manera eficiente.
            </p>
          </div>
          {/* Plugins de Integración */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <FaTools className="h-12 w-12 text-indigo-600 mx-auto mb-4" />
            <h4 className="text-xl font-bold mb-2">Plugins de Integración</h4>
            <p className="text-gray-600">
              Integra APIs, herramientas externas o sistemas avanzados a tu WordPress.
            </p>
          </div>
          {/* Plugins WordPress */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <FaWordpress className="h-12 w-12 text-indigo-600 mx-auto mb-4" />
            <h4 className="text-xl font-bold mb-2">Plugins para WordPress</h4>
            <p className="text-gray-600">
              Extiende y mejora la funcionalidad de tu WordPress con plugins personalizados.
            </p>
          </div>
        </div>
      </div>

      {/* Requerimientos */}
      <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <h3 className="text-2xl font-bold text-gray-800 mb-4">Requerimientos y Proceso de Compra</h3>
        <ul className="list-disc list-inside text-gray-700 leading-relaxed text-lg">
          <li>Es necesario contar con una cuenta <strong>KUV</strong> para comprar o cotizar un plugin.</li>
          <li><strong>Pago adelantado:</strong> Se requiere un pago inicial antes de iniciar el desarrollo.</li>
          <li><strong>Tiempo de desarrollo:</strong> El periodo mínimo de desarrollo y pruebas es de 3 días, dependiendo de la complejidad del plugin.</li>
          <li>Ofrecemos pruebas funcionales para garantizar que el plugin cumple con tus necesidades antes de su entrega final.</li>
        </ul>
      </div>

      {/* Información Adicional */}
      <div className="text-center py-12">
        <button
          onClick={handleInfoToggle}
          className="flex items-center justify-center text-indigo-600 hover:text-indigo-700 mx-auto"
        >
          <QuestionMarkCircleIcon className="h-6 w-6 mr-2" />
          ¿Cómo funciona el proceso de desarrollo?
        </button>
        {showInfo && (
          <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg max-w-4xl mx-auto">
            <p className="text-gray-700 text-lg">
              Una vez recibido tu pago inicial, trabajaremos en el desarrollo del plugin siguiendo tus especificaciones. Realizamos pruebas funcionales para garantizar un rendimiento óptimo antes de la entrega final.
            </p>
          </animated.div>
        )}
      </div>

      {/* Call to Action */}
      <div className="text-center py-12">
        <button className="bg-indigo-600 text-white text-lg font-medium px-6 py-3 rounded-md shadow-lg hover:bg-indigo-700">
          Cotizar mi Plugin
        </button>
      </div>
    </div>
  );
};

export default DeveloperPlugins;
