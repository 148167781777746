import React from 'react';

const MailSetupGuide = () => {
  return (
    <div className="container mx-auto p-6 bg-white text-black">
      <h1 className="text-3xl font-semibold mb-6 text-center">Guía de Instalación del Correo KUV Digital Empresarial</h1>
      
      <p className="text-lg mb-8 text-center">
        Para configurar correctamente tu correo electrónico empresarial de KUV Digital, lo primero que necesitas es contar con los datos de acceso correspondientes a la cuenta de correo que creaste al adquirir tu servicio de dominio y hosting. Estos datos incluyen tu dirección de correo y la contraseña que elegiste al momento de crear tu cuenta de correo. Si aún no has creado tu cuenta o necesitas obtener esta información, te invitamos a consultar nuestra guía paso a paso: <a href="/como-crear-una-cuenta-de-correo" className="text-indigo-600">Cómo Crear una Cuenta de Correo</a>. Allí encontrarás instrucciones detalladas sobre cómo crear y configurar tu dirección de correo personalizada, asegurando que puedas aprovechar al máximo los servicios que ofrecemos en KUV Digital.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Paso 1 */}
        <div className="step flex flex-col md:flex-row items-center mb-8">
          <div className="w-full md:w-1/2 mb-4 md:mb-0">
            <img
              src="../images/mail-setup-account.webp"
              alt="Accede a tu correo"
              className="w-full h-auto rounded-md shadow-md"
            />
          </div>
          <div className="w-full md:w-1/2 text-center md:text-left">
            <h2 className="text-2xl font-medium mb-4">1. Accede a tu correo KUV Digital</h2>
            <p className="text-lg mb-4">Abre la aplicación de correo en tu dispositivo móvil (Android) y selecciona "Agregar Cuenta".</p>
          </div>
        </div>

        {/* Paso 2 */}
        <div className="step flex flex-col md:flex-row items-center mb-8">
          <div className="w-full md:w-1/2 mb-4 md:mb-0">
            <img
              src="https://via.placeholder.com/500x300.png?text=Paso+2%3A+Configurar+Cuenta"
              alt="Configura tu Cuenta"
              className="w-full h-auto rounded-md shadow-md"
            />
          </div>
          <div className="w-full md:w-1/2 text-center md:text-left">
            <h2 className="text-2xl font-medium mb-4">2. Configura tu Cuenta</h2>
            <p className="text-lg mb-4">Ingresa la dirección de correo y la contraseña que creaste cuando configuraste tu correo.</p>
          </div>
        </div>

        {/* Paso 3 */}
        <div className="step flex flex-col md:flex-row items-center mb-8">
          <div className="w-full md:w-1/2 mb-4 md:mb-0">
            <img
              src="https://via.placeholder.com/500x300.png?text=Paso+3%3A+Configuración+IMAP+%2F+SMTP"
              alt="Configuración IMAP/SMTP"
              className="w-full h-auto rounded-md shadow-md"
            />
          </div>
          <div className="w-full md:w-1/2 text-center md:text-left">
            <h2 className="text-2xl font-medium mb-4">3. Configura los Servidores IMAP/SMTP</h2>
            <p className="text-lg mb-4">Para enviar y recibir correos, debes configurar los servidores IMAP y SMTP. Asegúrate de ingresar los datos correctos.</p>
          </div>
        </div>

        {/* Paso 4 */}
        <div className="step flex flex-col md:flex-row items-center mb-8">
          <div className="w-full md:w-1/2 mb-4 md:mb-0">
            <img
              src="https://via.placeholder.com/500x300.png?text=Paso+4%3A+Finalizar+Configuración"
              alt="Finaliza la configuración"
              className="w-full h-auto rounded-md shadow-md"
            />
          </div>
          <div className="w-full md:w-1/2 text-center md:text-left">
            <h2 className="text-2xl font-medium mb-4">4. Finaliza la Configuración</h2>
            <p className="text-lg mb-4">Revisa tu bandeja de entrada y realiza una prueba de envío de correo para confirmar que la configuración es correcta.</p>
          </div>
        </div>
      </div>

      <p className="mt-8 text-lg text-center">Si tienes algún problema durante la configuración, por favor contacta con nuestro soporte técnico.</p>
    </div>
  );
};

export default MailSetupGuide;
